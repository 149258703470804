const growthbook = require("../lib/growthbook");

document.addEventListener('DOMContentLoaded', async () => {
  await growthbook.initGrowthbook();

  if (growthbook.client().isOn('exp_header_value-plans-banner_v1')) {
      // setup banner functionality
      var closeBtn = document.getElementById("promo-banner-close");
      var signalPromoBanner = document.getElementById("promo-banner-signal");

      closeBtn.onclick = function(event) {
          signalPromoBanner.classList.add("hidden");
      };

      // show the banner
      if (signalPromoBanner) signalPromoBanner.style.display = 'block';
  }
});
